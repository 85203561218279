.statInfo-container {
    background-color: $color-brand-black;
    color: $white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    font-family: 'Poppins';

    @media(min-width:768px) {
        padding: 50px 30px;
    }

    &.light {
        background: $grey-new;

        .statInfo-firstTitle, .statInfo-statisticNumber .finalNumber {
            color: $darkblue-update;
        }

        .statInfo-statistic {
            .statInfo-statisticNumber {
                color: $darkblue-update;
            }
        }

        .statInfo-secondTitle, .statInfo-statisticBody {
            color: $color-brand-black;
            text-align: left;
        }
    }

    .statInfo-titleWrapper {
        box-sizing: content-box;
        height: 125px;
        overflow: hidden;

        span {
            display: block;
            height: 100%;
            animation: spin_words 4s infinite;
        }
    }

    .statInfo-firstTitle, .statInfo-secondTitle {
        font-size: 40px;
        line-height: 1.25;

        @media (min-width: 768px) {
            font-size: 68px;
        }
    }

    .statInfo-firstTitle {
        color: $lightblue-update;
        display: block;
    }

    .statInfo-statisticList {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    .statInfo-statistic {
        padding: 20px;
        border: 1px solid rgba(108, 172, 228, 0.30);
        border-bottom: 0;

        @media (min-width: 768px) {
            padding: 65px 40px;
            border-right: 0;
            border-bottom: 1px solid rgba(108, 172, 228, 0.30);
            text-align: center;
        }

        &:last-child {
            border-bottom: 1px solid rgba(108, 172, 228, 0.30);

            @media (min-width: 768px) {
                border-right: 1px solid rgba(108, 172, 228, 0.30);
            }
        }

        .statInfo-statisticNumber {
            color: $lightblue-update;
            font-family: Poppins;
            font-size: 80px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            margin: 0 0 20px;
            display: inline-block;
        }
    }

    .statInfo-statNumber {
        color: $lightblue-update;
        text-align: center;
        font-size: xxx-large;
        margin-bottom: 15px
    }

    .statInfo-statBody {
        text-align: left;
        padding-left: 40px;
        padding-right: 40px;
    }
}

@keyframes spin_words {
    10% {
        transform: translateY(-112%);
    }

    25% {
        transform: translateY(-100%);
    }

    35% {
        transform: translateY(-212%);
    }

    50% {
        transform: translateY(-200%);
    }
}


.statInfo-wrapper {
    overflow: hidden;
    height: 50px;

    @media (min-width: 768px) {
        height: 170px;
    }

    .statInfo-titleContent {
        margin-top: 0;
        text-align: left;
        list-style: none;
        -webkit-animation-name: change;
        -webkit-animation-duration: 10s;
        -webkit-animation-iteration-count: infinite;
        animation-name: change;
        animation-duration: 10s;
        animation-iteration-count: infinite;

        > span {
            line-height: 40px;
            margin: 0;
        }
    }
}

@-webkit-keyframes change {
    0%, 12.66%, 100% {
        transform: translate3d(0,0,0);
    }

    16.66%, 29.32% {
        transform: translate3d(0,-25%,0);
    }

    33.32%, 45.98% {
        transform: translate3d(0,-50%,0);
    }

    49.98%, 62.64% {
        transform: translate3d(0,-75%,0);
    }

    66.64%, 79.3% {
        transform: translate3d(0,-50%,0);
    }

    83.3%, 95.96% {
        transform: translate3d(0,-25%,0);
    }
}


@-o-keyframes change {
    0%, 12.66%, 100% {
        transform: translate3d(0,0,0);
    }

    16.66%, 29.32% {
        transform: translate3d(0,-25%,0);
    }

    33.32%,45.98% {
        transform: translate3d(0,-50%,0);
    }

    49.98%,62.64% {
        transform: translate3d(0,-75%,0);
    }

    66.64%,79.3% {
        transform: translate3d(0,-50%,0);
    }

    83.3%,95.96% {
        transform: translate3d(0,-25%,0);
    }
}

@-moz-keyframes change {
    0%, 12.66%, 100% {
        transform: translate3d(0,0,0);
    }

    16.66%, 29.32% {
        transform: translate3d(0,-25%,0);
    }

    33.32%,45.98% {
        transform: translate3d(0,-50%,0);
    }

    49.98%,62.64% {
        transform: translate3d(0,-75%,0);
    }

    66.64%,79.3% {
        transform: translate3d(0,-50%,0);
    }

    83.3%,95.96% {
        transform: translate3d(0,-25%,0);
    }
}


@keyframes change {
    0%, 12.66%, 100% {
        transform: translate3d(0,0,0);
    }

    16.66%, 29.32% {
        transform: translate3d(0,-25%,0);
    }

    33.32%,45.98% {
        transform: translate3d(0,-50%,0);
    }

    49.98%,62.64% {
        transform: translate3d(0,-75%,0);
    }

    66.64%,79.3% {
        transform: translate3d(0,-50%,0);
    }

    83.3%,95.96% {
        transform: translate3d(0,-25%,0);
    }
}
