.featured-news-container {
    background-color: $color-brand-black;
    font-family: Poppins;
    color: $white;
    padding: 20px;
    display: flex;
    flex-direction: column;

    @media(min-width:768px) {
        padding: 50px 30px;
    }

    .featured-news-title {
        h2 {
            font-size: 36px;

            @media(min-width:768px) {
                font-size: 56px;
            }
        }

        .featured-news-sub-title {
            display: flex;
            align-items: center;
        }

        h3 {
            font-size: 20px;
            font-style: normal;
            line-height: 130%;

            @media(min-width:768px) {
                font-size: 28px;
            }
        }
    }

    a {
        text-decoration: underline;
        color: $color-brand-black;
    }

    .news-card-container {
        display: block;
        flex-direction: row;
        justify-content: space-evenly;
        text-align: left;
        align-items: flex-start;
        gap: 30px;
        overflow: hidden;
        margin-top: 40px;

        .slick-track {
            margin: 0 auto;
            display: flex;
        }

        @media(min-width:769px) {
            display: flex;
        }
    }

    .slick-dots li.slick-active button:before {
        color: $white;
    }

    .slick-dots li button:before {
        color: $white;
    }

    .news-card-content {
        padding-right: 8px;

        @media(min-width:768px) {
            padding-right: 0;
        }
    }

    .image-animate {
        overflow: hidden;
        border: 5px solid transparent;
        transition: all 0.5s ease-in-out;

        &:hover {
            border: 5px solid $darkblue-update;

            img {
                transform: scale(1.2);
            }
        }
    }

    .slick-list {
        padding-left: 0 !important;
    }

    .news-card {


        @media(min-width:768px) {
            max-width: 600px;
            width: 33.33%;
        }

        img {
            width: 100%;
            height: auto;
            transition: all 0.3s ease-in-out;
        }

        .featured-news-headline {
            color: $white;

            &:hover {
                color: $white;
            }
        }
    }

    .news-cardHeadline {
        text-decoration: underline;
    }

    .featured-news-headline {
        color: $white;

        &:hover {
            color: $lightblue-update;
        }
    }

    .slick-dots {
        display: flex;
        justify-content: center;
        margin: 20px 0 0 0;
        padding: 1rem 0;
        list-style-type: none;

        li {
            margin: 0 0.25rem;

            &.slick-active {
                button {
                    background: $lightblue-update;
                    width: 10px;
                    height: 10px;
                }
            }

            button {
                display: block;
                width: 8px;
                height: 8px;
                padding: 0;
                border: none;
                border-radius: 100%;
                background-color: $bullet-color;
                text-indent: -9999px;
            }
        }
    }
}

.featured-news-container.light {
    background-color: $light-background-update;
    color: $color-brand-black;

    .featured-news-title {
        h3 {
            color: $darkblue-update;
        }
    }

    .featured-news-cta {
        color: $darkblue-update;

        span {
            &:after {
                color: $darkblue-update;
            }
        }
    }

    .news-card-container {
        h2 {
            color: $color-brand-black;
        }
    }

    .featured-news-headline:hover {
        color: $color-brand-black;
    }

    .featured-news-cta:hover {
        color: $red-update;
    }

    .slick-dots li.slick-active button:before {
        color: $white;
    }

    .slick-dots li button:before {
        color: $white;
    }

    .slick-dots {

        li {

            &.slick-active {
                button {
                    background: $color-brand-black;
                }
            }
        }
    }
}

@keyframes bounceAlpha {
    0% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }

    25% {
        opacity: 0;
        transform: translateX(10px) scale(0.9);
    }

    26% {
        opacity: 0;
        transform: translateX(-10px) scale(0.9);
    }

    55% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
}


a.rounded-button-container {
    position: relative;
    color: $lightblue-update;
    text-decoration: none;
    margin-top: 32px;
    display: inline-flex;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    text-transform: capitalize;
    align-items: center;
    text-decoration: none;

    @media (max-width: 768px) {
        margin-top: 26px;
        font-size: 20px;
    }

    &:hover {
        color: $red;

        .first-arrow {
            left: 71px;
            opacity: 0;
        }

        .second-arrow {
            left: 50%;
            opacity: 1;
        }

        .second-arrow, .first-arrow {
            svg path {
                stroke: $white;
            }
        }

        .animated-arrow-rounded {
            border: 1px solid $red;


            &::after {
                animation-name: bounceAlpha;
                animation-duration: 1.4s;
                animation-delay: 0.4s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }

            &::before {
                transform: translateX(0);
            }
        }
    }

    .first-arrow, .second-arrow {
        font-family: Fontawesome;
        color: $lightblue-update;
        border: none;
        position: absolute;
        transition: left 0.2s ease-in, opacity 0.2s ease-in;
        font-size: 26px;
        top: 50%;
        line-height: 0;
        transform: translate(-50%, -50%);


        svg {
            height: 25px;
            width: 25px;
        }
    }

    .first-arrow {
        left: 50%;
        opacity: 1;
    }

    .second-arrow {
        left: -36px;
        opacity: 0;
    }

    .animated-arrow-rounded {
        height: 48px;
        width: 48px;
        border: 1px solid $lightblue-update;
        border-radius: 50%;
        line-height: 1;
        overflow: hidden;
        position: relative;
        margin-left: 25px;

        @media (min-width: 768px) {
            height: 72px;
            width: 72px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $red;
            transform: translateX(-100%);
            transition: transform 0.4s ease-in-out;
            border-radius: 50%;
        }
    }
}
