.footer {
    background: $color-brand-black;
    position: relative;
    z-index: 1; 
    a{
        &:hover {
            color: $darkblue-update;
            text-decoration: none;
        }
    }
    &.light {
        background: $grey-new;

        .footer-top-left-btnlist a {
            border: 1px solid $color-brand-black;
        }

        .footer-top-left-social li {
            border: 1px solid $color-brand-black;

            img {
                filter: invert(1);
            }

            &:hover {
                border: 2px solid $color-brand-black;
            }
        }

        a, ul, li, div, p, i, h3 {
            color: $color-brand-black;
        }

        .footer-top-right-column {
            @media (max-width: 768px) {
                border-bottom: 1px solid $color-brand-black;
            }

            &.column1 {
                a {
                    @media (min-width: 768px) {
                        &:before {
                            background-color: $color-brand-black;
                        }
                    }
                }
            }
        }

        svg {
            path {
                fill: $color-brand-black;
            }
        }

        &-top {
            &-left {
                &-btnlist {
                    a {
                        border: 1px solid rgba(0, 0, 0, 0.30);
                    }
                }

                &-social {
                    li {
                        border: 1px solid rgba(0, 0, 0, 0.30);
                    }
                }
            }
        }
    }

    &-container {
        @media(min-width:768px) {
          //  padding: 0 60px;
        }

        @media(max-width:768px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            text-align: center;
        }
    }

    a, ul, li, div, p, i, h3 {
        color: $white;
    }

    ul {
        padding: 0;
        list-style-type: none;
    }

    i {
        font-family: 'FontAwesome';
        font-size: 20px;
    }

    &-top {
        padding: 54px 0 70px;

        @media(max-width:768px) {
            width: 100%;
            padding: 30px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &-left {
            > div {
                @media(max-width:768px) {
                    margin-bottom: 40px;
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }

            @media(min-width:768px) {
                width: 23%;
            }

            &-logo {
                @media(min-width:768px) {
                    margin-bottom: 48px;
                }
            }

            &-btnlist {
                display: flex;

                @media(min-width:768px) {
                    margin-bottom: 48px;
                }

                a {
                    border: 1px solid rgba(255, 255, 255, 0.30);
                    font-size: 16px;
                    line-height: 110%;
                    padding: 12px;
                    margin-right: 16px;
                    width: 100%;
                    text-align: center;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    text-transform: capitalize;

                    &:hover {
                       text-decoration: none;
                        color: $darkblue-update !important;
                        

                        .animated-arrow {
                            .first-arrow {
                                left: 30px;
                                opacity: 0;
                            }

                            .second-arrow {
                                left: 0;
                                opacity: 1;
                            }
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    .animated-arrow {
                        margin-left: 10px;
                        position: relative;
                        width: 20px;
                        overflow: hidden;
                        height: 20px;

                        > span {
                            position: absolute;
                            transition: left 0.5s ease-in, opacity 0.5s ease-in;
                            top: 0;
                        }

                        .first-arrow {
                            left: 0;
                            opacity: 1;
                        }

                        .second-arrow {
                            left: -36px;
                            opacity: 0;
                        }
                    }
                }
            }

            &-social {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
                margin: 0;
                max-width: 100%;

                @media(max-width:768px) {
                    justify-content: center;
                    display: none;
                }

                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid rgba(255, 255, 255, 0.30);
                    border-radius: 50%;
                    height: 56px;
                    width: 56px;
                    min-width: 56px;
                    margin: 0 12px 12px 0;


                    &:hover {
                       // border: 2px solid $white;
                       // transition: border 100ms linear;
                        cursor: pointer;
                        color: $darkblue-update;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &-right {
            @media(max-width:768px) {
                text-align: center;
            }

            @media(min-width:768px) {
                width: 74%;
                padding-left: 10%;
                font-weight: 600;

                &-container {
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: -20px;
                    margin-right: -20px;
                }

                &-column {
                    width: 25%;
                    padding-left: 20px;
                    padding-right: 20px;

                    &.column1 {
                        ul {
                            li {

                                &:first-child {
                                    a {
                                        font-size: 18px;
                                    }
                                }
                            }
                        }

                        a {
                            font-size: 18px;
                            position: relative;
                            text-decoration: none;
                            font-weight: 600;
                            letter-spacing: 1px;
                            text-transform: capitalize;

                            &:hover {
                                text-decoration: none;
                                color: $darkblue-update;
                                &:before {
                                    visibility: visible;
                                    width: 100%;
                                }
                            }
                        }
                    }

                    ul {
                        
                        li {
                            a {
                                font-size: 16px;

                                &:hover{
                                    color: $darkblue-update;
                                    text-decoration: none;
                                }
                            }

                            &:first-child {
                                a {
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }

                &-title {
                    display: none;

                    @media(max-width:768px) {
                        display: block;
                    }
                }

                &-list {
                    margin: 0;

                    &-item {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    &-bottom {
        padding: 0 0 40px;

        .footer-top-left-social {
            display: none;

            @media(max-width:768px) {
                display: flex;
                flex-wrap: wrap;
                max-width: 100%;
            }
        }

        &-right {
            &-list {

                &-item {
                    font-size: 12px;
                    margin: 15px 0 0 0;
                }
            }
        }

        &-left {
            span {
                font-size: 14px;
            }
        }

        @media(min-width:768px) {


            &-left {
                width: 20%;
            }

            &-right {
                width: 80%;
                display: flex;
                justify-content: flex-end;

                &-list {
                    display: flex;
                    justify-content: center;
                    margin-top: 0;

                    &-item {
                        font-size: 14px;
                        margin: 0 20px 0 0;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &-top, &-bottom {
        @media(min-width:768px) {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        font-family: Poppins;
        font-weight: 400;
    }
}

@media (max-width: 768px) {
    .footer {
        &-top {
            &-right {
                &-column {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                    padding: 25px 0;

                    ul {
                        li {
                            margin-top: 20px;
                        }
                    }

                    &.column1 {
                        h3 {
                            display: none;
                        }

                        ul {
                            font-size: 20px;
                        }
                    }
                }

                &-title {
                    margin: 0;
                    position: relative;
                    font-weight: 300;
                    line-height: 1;

                    &::after {
                        position: absolute;
                        content: "\f105";
                        font-family: FontAwesome;
                        margin-left: 15px;
                        font-size: 20px;
                        transform: rotate(90deg);
                    }
                }

                &-list {
                    display: none;
                    margin: 0;
                }
            }
        }
    }

    .footer-top-right-column {
        .footer-top-right-list-active {
            display: block;
        }
    }

    .footer-top-right-column.column1 {
        display: block;

        .footer-top-right-title {
            &::after {
                content: none;
            }
        }

        .footer-top-right-list {
            display: block;
        }
    }

    .footer-top-right-column-active {
        h3 {
            &::after {
                transform: rotate(270deg) !important;
            }
        }
    }
}

@keyframes bounceAlpha {
    0% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }

    100% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
}
