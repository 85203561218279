.secondary-carousel {
    background: linear-gradient(180deg, #E5E1E6 50%, #C0C3D8 100%);
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;

    @media(min-width: 768px){
        padding-left: 64px;
        padding-right: 64px;
        padding-top: 64px;
    }
    &_slide{
       display: block;
        background: $white;

        @media(min-width: 768px){
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            gap: 20px;
            padding: 20px;
            box-shadow:  -6px 1px 20px 3px rgba(137, 139, 157, 0.5);
            //inset -10px -1px 20px 0px rgba(137, 139, 157, 0.50); 
        }

        &-col{
            width: 100%;
            @media(min-width: 768px){
                width: 50%;
            }
        }
        &-col:first-child > img {
            height: 120px;
            width: 100%;
            object-fit: cover;
            @media(min-width: 768px){
                height: 320px;
            }

        }
        &-col:nth-child(2) {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 16px;
            padding-right: 16px;

            @media(min-width: 768px){
                padding-bottom: 20px;
                padding-left: initial;
                padding-right: initial;
            }
            p {
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 19.6px */
                text-transform: uppercase;
            }
            a > span {
                color: $darkblue-update;
                font-family: Poppins;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 33.6px */
            }
            a > img {
                @media(min-width: 768px){
                    padding-top: 30px; 
                }
                padding-top: 10px;
            }

        }

    }
}

.secondary-carousel-arrows {   
        display: flex;
        justify-content: center;
        padding-top: 32px;
        padding-bottom: 48px;
        @media(min-width: 768px){
            padding-top: 28px;
            padding-bottom: 28px;
        }
}