.experience-toggle-panel{
    h2{
        color: $white;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 46.8px */

        @media(min-width: 768px){
            font-size: 36px;
        }
    }
    &_services, &_regions {
        padding: 16px;

        @media(min-width: 768px){
            padding : 40px;
        }
        .toggle-container{
            position: relative;

            .ser-toggle-btn, .geo-toggle-btn, .toggle-btn{
                border-radius: 11px;
                border: 1px solid #C0C3D8;
                background: #C0C3D8;
                color: $darkblue-update;
                text-align: center;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
                text-transform: uppercase;
                border-radius: 11px;
                padding-left: 8px;
                padding-right: 8px;
                margin-top: 10px;
    
                &.active{
                    border: 1px solid $lightblue-update;
                    background: $white;
                    color: $color-brand-black;
                    padding-right: 16px;
                    position: relative;
                    z-index: 11;
                }
            }
            .ser-toggle-btn {
                padding-left: 20px;
                position: absolute;
                left: 73px;
                z-index: 10px;
            }
            .geo-toggle-btn{
                padding-right: 20px;
            }
            .geo-toggle-btn + .toggle-btn {
                position: absolute;
                left: 73px;
                padding-right: 8px;
            }
        }

    }
    .panel{
        display: none;
    }
    .panel.active {
        display: block;
    }
    &_regions{
        .content {
            padding-top: 52px;
           display: grid;
           grid-template-columns: 1fr;
           row-gap: 16px;
           width: 100%;

           @media(min-width: 768px) {
            grid-template-columns: 1fr 1fr 1fr;
            width: 55%;
            column-gap: 40px;           
        }

            p{
                margin: 0;
                display: inline-block;
                width: 100%;
            }
            a{
                display: inline-block;
                span{
                    color: $white;
                    font-family: Poppins;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%; /* 39.2px */
                    text-transform: capitalize;

                    @media(min-width: 768px) {
                        font-size: 28px;
                    }
                }
               
            }
          
        }
        &-title{
            color: $white;
            font-family: Poppins;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%; /* 70.2px */
            width: 100%;
            padding-top: 86px;
            padding-bottom: 64px;

            @media(min-width: 768px) {
                width: 60%;
                font-size: 54px;
            }

        }
    }
    &_services{

        .content{
            padding-top: 56px;
            display: grid;
           grid-template-columns: 1fr 1fr;
           column-gap: 32px;
           row-gap: 16px;
           width: 100%;

            @media(min-width: 768px){
                grid-template-columns: 1fr 1fr 1fr 1fr;
                
            }

            p {
                margin: 0;
                display: inline-block;
                width: 100%;
            }
            a{
                display: inline-block;
                span{
                    @media(min-width: 768px){
                        font-size: 20px;
                    }
                    color: $white;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%; /* 28px */
                    text-transform: capitalize;
                    &:hover {
                        color: $grey-darkest;
                    }
                }
                
            }

        }
    }
    input[type="radio"].toggle {
        display: none;
    
        & + label{
            position: relative;
            cursor: pointer;
            min-width: 60px;
            &:hover{
                background: none;
                color: yellow;
            }
            &:after{
                background: red;
                content: "";
                height: 100%;
                position: absolute;
                top: 0;
                transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
                width: 100%;
                z-index: 0;
                opacity: 0.5;
            }
        }
        &.toggle-left + label {
            border-right: 0;
            &:after{
                left: 0;
            }
        }
        &.toggle-right + label{
            margin-left: -5px;
            &:after{
                left: -100%;
            }
        }
        &:checked + label {
            cursor: default;
            color: #fff;
            transition: color 200ms;
            &:after{
                left: 0;
            }
        }
    }
    

}