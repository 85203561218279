.primary-carousel{
    display: none !important;
    @media(min-width: 768px){
        display: block !important;
    }
    background: linear-gradient(180deg, #E5E1E6 50%, #C0C3D8 100%);

    &-cardImg{
        height: 120px;
        width: 100%;
        img{
            height: inherit;
            width: 100%;
            object-fit: cover;
        }
    }
   &-bgImg{
    height: 368px;
    img{
        height: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
    }
   }
   &-slide{
    &_content{
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
    gap: 26px;
    margin-inline-start: 16px;
    margin-inline-end: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    @media(min-width: 768px){
        margin-inline-start: 64px;
        margin-inline-end: 64px;
        margin-top: 48px;
    }
    }
   }
   &-card{
    display: flex;
    flex-basis: 100%; 
    height: 368px;
    background: $white;
    box-shadow: 5px 10px 10px 0px $shodow-grey1;
    position: relative;

    .card-text-container{
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 20px;
        padding-top: 20px;
        @media(min-width: 768px){
           padding: initial;
        }


        p{
            color: $color-brand-black;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 19.6px */
            text-transform: uppercase;
            margin: 20px 0 0;
        }
        .card-title{
            margin-top: 10px;
            a {
                color: $darkblue-update;
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;

                @media(min-width: 768px) and (max-width: 1024px){
                    font-size: 12px;
                }

                img {
                    margin-top: 10px;
                }
            }
         
        }
    }
    .full-bg{
        position: absolute;
        padding: 20px;
        bottom: 0;
        transition: height 0.2s ease-in-out;
        p{
            color: $white;
        }

        .card-title{
            a{
                color: $white;
            }          
        }
    }
  
   }
   &-lite {
    display: block !important;
    @media(min-width: 768px){
        display: none !important;
    }
    
   }
}
.primary-carousel-card:has(.primary-carousel-cardImg) ,.primary-carousel-card:not(:has(.primary-carousel-bgImg))  {
    flex-direction: column;
    @media(min-width: 768px){
        padding: 20px;
    }
  }

  .slick-dots li{
    @media(min-width: 768px){
        margin: 0 32px !important;
    }
    margin: 0 24px !important;
    padding: 5px !important;
  }
  .slick-dots li button:before{
        width: 18px !important;
        height: 18px !important;
        content: '' !important;
        border-radius: 17.5px !important;
        display: inline-block !important;
        border: 2px solid $darkblue-update !important;
    
   }
   .slick-dots li.slick-active button:before {
    opacity: 1;
    background-color: $darkblue-update;
    content:'';
}

.slick-dots {
    position: initial !important;
    width: initial !important;
    padding: 5px !important;
}
.slides-arrows-full, .slides-arrows-lite {
    display: flex;
    justify-content: center;
    padding-top: 32px;
    padding-bottom: 48px;
}
.slick-slider {
    margin-bottom: initial !important;
}