body {
    .global-page-title {
        background-image: linear-gradient(to right,$darkblue-update,$darkblue-update);
    }

    .bbt-listings .sector-tabs .sector-tab-view .service-content h2 a {
        color: $darkblue-update;
    }

    .grid-content-main .featured-news-container {
        margin-top: 40px;
    }
}
