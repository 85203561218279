.in-the-know {
    background: $grey-new;
    padding: 40px;
    font-family: Poppins;

    @media (max-width: 768px) {
        padding: 55px 20px 35px;
    }

    > div {
        padding: 0;
    }

    &-title {
        font-size: 48px;
        color: $color-brand-black;

        @media (max-width: 768px) {
            font-size: 28px;
        }
    }

    h3 {
        color: $color-brand-black;

        @media (max-width: 768px) {
            font-size: 18px;
        }
    }

    &-container {
        @media (min-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            margin-left: -20px;
            margin-right: -20px;
            margin-top: 40px;
        }
    }

    &-left-column {
        @media (min-width: 768px) {
            width: 40%;
            padding: 0 20px;
        }

        @media (max-width: 768px) {
            margin: 35px -20px 20px;
        }

        h2 {
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
            text-transform: capitalize;
            color: $white;
            margin-bottom: 20px;
            font-family: 'Poppins';

            @media (min-width: 768px) {
                font-size: 36px;
                margin-bottom: 40px;
            }
        }

        &-container {
            background: $darkblue-update;
            padding: 52px 40px 53px 40px;
            color: $white;
            height: 100%;

            @media (max-width: 768px) {
                padding: 40px 20px;
            }
        }


        .see-all-events {
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            color: $white;
            display: flex;
            align-items: center;

            .animated-arrow-rounded {
                &:before {
                    background-color: $white;
                }
            }

            &:hover {
                color: $white;

                .animated-arrow-rounded {
                    border: 1px solid $white;

                    .second-arrow {
                        svg {
                            path {
                                stroke: $color-brand-black;
                            }
                        }
                    }
                }
            }
        }

        .event-section {
            span {
                display: block;
                font-size: 16px;
                margin-bottom: 10px;

                a {
                    color: $white;
                    font-size: 16px;
                    background-image: linear-gradient(transparent calc(100% - 2px), #fff 2px);

                    @media (min-width: 768px) {
                        font-size: 24px;
                    }
                }
            }
        }
    }

    &-right-column {
        @media (min-width: 768px) {
            width: 60%;
            padding: 0 20px;
        }

        &-container {
        }

        &-featured-tile {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            background: $white;
            margin-bottom: 20px;
            align-items: center;

            &:last-child {
                margin-bottom: 0;
            }

            &-left {
                width: 40%;

                @media (max-width: 768px) {
                    width: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    max-height: 312px;
                }
            }

            &-right {
                flex: 1;
                padding: 20px;

                @media (min-width: 768px) {
                    padding: 40px;
                }

                .arrow-animation {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    color: #3B58A2;
                    display: inline-flex;
                    cursor: pointer;

                    @media (max-width: 768px) {
                        font-size: 20px;
                    }

                    &:hover {
                        color: #3B58A2;
                    }
                }

                h2 {
                    color: $red-new;
                    font-size: 20px;
                    margin-top: 20px;

                    @media (min-width: 768px) {
                        font-size: 24px;
                    }

                    a {
                        color: $red-new;

                        &:before {
                            background-color: $red-new;
                        }
                    }
                }
            }
        }
    }
}

.in-the-know.light {
    background-color: $grey-new;

    .in-the-know-title, .in-the-know-sub-title {
        color: $color-brand-black;
    }
}

.in-the-know.dark {
    background-color: $color-brand-black;

    .in-the-know-title, .in-the-know-sub-title {
        color: $white;
    }
}

.arrow-animation {
    margin-right: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    &:hover {

        .animated-arrow {
            .first-arrow {
                left: 30px;
                opacity: 0;
            }

            .second-arrow {
                left: 0;
                opacity: 1;
            }
        }
    }

    &:last-child {
        margin-right: 0;
    }

    .animated-arrow {
        margin-left: 10px;
        position: relative;
        width: 20px;
        overflow: hidden;
        height: 20px;
        display: inline-flex;

        > span {
            position: absolute;
            transition: left 0.5s ease-in, opacity 0.5s ease-in;
            top: 0;
        }

        .first-arrow {
            left: 0;
            opacity: 1;
        }

        .second-arrow {
            left: -36px;
            opacity: 0;
        }
    }
}

a.underline-animation {
    text-decoration: none;
    position: relative;

    &:hover {
        &:before {
            visibility: visible;
            width: 100%;
        }
    }

    &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
    }

    @media (min-width: 768px) {
        font-size: 24px;
    }
}
a.multiline-animation {
    width: calc(100%);
    background-image: linear-gradient(transparent calc(100% - 2px), $color-brand-black 2px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s;
    text-decoration: none;

    &:hover {
        background-size: 100% 100%;
    }
}
