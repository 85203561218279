.video-container-main {
    max-height: 640px;
    min-width: 100%;
    position: relative;
    @media (min-width: 768px) {
    max-height: 586px; 
    }

    &:after {
        content: '';
        position: absolute;
        background: rgba(0, 0, 1, 0.5);
        border-radius: 5px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

    #banner-image {
        display: none;
        margin: auto;
        margin-top: 50px;

        @media (max-width: 768px) {
            display: block;
            margin-top: 0;
            max-height: 640px;
            width: 100%;
        }
    }

    #banner-video {
        display: block;
        max-height: 586px;
        object-fit: cover;
        position: relative;
        z-index: 0;
        @media (max-width: 768px) {
            
            display: none;
        }
    }
    .text-overlay{
        position: absolute;
        top: 466px;
        left: 16px;
        z-index: 1;       
        font-family: Poppins;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; 
        max-width: 360px;
        @media (min-width: 768px) {
            top: 53%;
            left: 64px; 
            max-width: 1087px;
            font-size: 68px;
        }
        .blue{
            color: $lightblue-update;
        }
        .white {
            color: $white;
            margin: 0;
        }

    }
}
