.featured-banner-container-main {
    background-color: $color-brand-black;
    font-family: Poppins;

    .featured-banner-img-container {
        position: relative;

        img {
            width: 100%;
            max-height: 570px;
            object-fit: cover;
            object-position: center;
        }

        .featured-banner-overlay {
            position: absolute; 
            width: 100%;
            height: 100%;
            background-color: $site-opaque-bg;
            bottom: 0;
        }

        .featured-banner-overlay-txt { 
            z-index: 1;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            height: fit-content;
            width: 100%;
            color: $white;
            text-align: center;
            font-size: 100px;
            font-weight: 600;

            @media (max-width: 768px) {
                font-size: 52px;
                line-height: 57.2px;
            }
        }
    }

    .featured-banner-learn-more {
        cursor: pointer;
    }

    .featured-banner-content {
        padding: 80px 64px;
        font-family: Poppins;

        > div {
            padding: 0;
        }

        @media (max-width: 768px) {
            padding: 60px 20px;
        }

        p {
            color: $white;
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
            margin: 0px;

            @media (max-width: 768px) {
                font-size: 28px;
            }
        }
    }
}

.featured-banner-container-main.light {
    .featured-banner-content {
        background-color: $grey-new;

        p {
            color: $color-brand-black;
        }
    }

    .featured-banner-learn-more {
        color: $darkblue-update;

        span.animated-arrow {
            border: 1px solid $darkblue-update;

            .first-arrow, .second-arrow {
                color: $darkblue-update;
            }
        }
    }
}



@keyframes bounceAlpha {
    0% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }

    100% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
}
