@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap");
/*@import "../../globals";*/
/* ----------------------------------------------------
   Home Page COMPONENTS
------------------------------------------------------- */
.featured-banner-container-main {
  background-color: #000;
  font-family: Poppins; }
  .featured-banner-container-main .featured-banner-img-container {
    position: relative; }
    .featured-banner-container-main .featured-banner-img-container img {
      width: 100%;
      max-height: 570px;
      object-fit: cover;
      object-position: center; }
    .featured-banner-container-main .featured-banner-img-container .featured-banner-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      bottom: 0; }
    .featured-banner-container-main .featured-banner-img-container .featured-banner-overlay-txt {
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: fit-content;
      width: 100%;
      color: #ffffff;
      text-align: center;
      font-size: 100px;
      font-weight: 600; }
      @media (max-width: 768px) {
        .featured-banner-container-main .featured-banner-img-container .featured-banner-overlay-txt {
          font-size: 52px;
          line-height: 57.2px; } }
  .featured-banner-container-main .featured-banner-learn-more {
    cursor: pointer; }
  .featured-banner-container-main .featured-banner-content {
    padding: 80px 64px;
    font-family: Poppins; }
    .featured-banner-container-main .featured-banner-content > div {
      padding: 0; }
    @media (max-width: 768px) {
      .featured-banner-container-main .featured-banner-content {
        padding: 60px 20px; } }
    .featured-banner-container-main .featured-banner-content p {
      color: #ffffff;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      margin: 0px; }
      @media (max-width: 768px) {
        .featured-banner-container-main .featured-banner-content p {
          font-size: 28px; } }

.featured-banner-container-main.light .featured-banner-content {
  background-color: #E5E1E6; }
  .featured-banner-container-main.light .featured-banner-content p {
    color: #000; }

.featured-banner-container-main.light .featured-banner-learn-more {
  color: #0047BB; }
  .featured-banner-container-main.light .featured-banner-learn-more span.animated-arrow {
    border: 1px solid #0047BB; }
    .featured-banner-container-main.light .featured-banner-learn-more span.animated-arrow .first-arrow, .featured-banner-container-main.light .featured-banner-learn-more span.animated-arrow .second-arrow {
      color: #0047BB; }

@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateX(0px) scale(1); }
  100% {
    opacity: 1;
    transform: translateX(0px) scale(1); } }

.video-container-main {
  max-height: 640px;
  min-width: 100%;
  position: relative; }
  @media (min-width: 768px) {
    .video-container-main {
      max-height: 586px; } }
  .video-container-main:after {
    content: '';
    position: absolute;
    background: rgba(0, 0, 1, 0.5);
    border-radius: 5px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .video-container-main #banner-image {
    display: none;
    margin: auto;
    margin-top: 50px; }
    @media (max-width: 768px) {
      .video-container-main #banner-image {
        display: block;
        margin-top: 0;
        max-height: 640px;
        width: 100%; } }
  .video-container-main #banner-video {
    display: block;
    max-height: 586px;
    object-fit: cover;
    position: relative;
    z-index: 0; }
    @media (max-width: 768px) {
      .video-container-main #banner-video {
        display: none; } }
  .video-container-main .text-overlay {
    position: absolute;
    top: 466px;
    left: 16px;
    z-index: 1;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    max-width: 360px; }
    @media (min-width: 768px) {
      .video-container-main .text-overlay {
        top: 53%;
        left: 64px;
        max-width: 1087px;
        font-size: 68px; } }
    .video-container-main .text-overlay .blue {
      color: #6cace4; }
    .video-container-main .text-overlay .white {
      color: #ffffff;
      margin: 0; }

.in-the-know {
  background: #E5E1E6;
  padding: 40px;
  font-family: Poppins; }
  @media (max-width: 768px) {
    .in-the-know {
      padding: 55px 20px 35px; } }
  .in-the-know > div {
    padding: 0; }
  .in-the-know-title {
    font-size: 48px;
    color: #000; }
    @media (max-width: 768px) {
      .in-the-know-title {
        font-size: 28px; } }
  .in-the-know h3 {
    color: #000; }
    @media (max-width: 768px) {
      .in-the-know h3 {
        font-size: 18px; } }
  @media (min-width: 768px) {
    .in-the-know-container {
      display: flex;
      flex-wrap: wrap;
      margin-left: -20px;
      margin-right: -20px;
      margin-top: 40px; } }
  @media (min-width: 768px) {
    .in-the-know-left-column {
      width: 40%;
      padding: 0 20px; } }
  @media (max-width: 768px) {
    .in-the-know-left-column {
      margin: 35px -20px 20px; } }
  .in-the-know-left-column h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    text-transform: capitalize;
    color: #ffffff;
    margin-bottom: 20px;
    font-family: 'Poppins'; }
    @media (min-width: 768px) {
      .in-the-know-left-column h2 {
        font-size: 36px;
        margin-bottom: 40px; } }
  .in-the-know-left-column-container {
    background: #0047BB;
    padding: 52px 40px 53px 40px;
    color: #ffffff;
    height: 100%; }
    @media (max-width: 768px) {
      .in-the-know-left-column-container {
        padding: 40px 20px; } }
  .in-the-know-left-column .see-all-events {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: #ffffff;
    display: flex;
    align-items: center; }
    .in-the-know-left-column .see-all-events .animated-arrow-rounded:before {
      background-color: #ffffff; }
    .in-the-know-left-column .see-all-events:hover {
      color: #ffffff; }
      .in-the-know-left-column .see-all-events:hover .animated-arrow-rounded {
        border: 1px solid #ffffff; }
        .in-the-know-left-column .see-all-events:hover .animated-arrow-rounded .second-arrow svg path {
          stroke: #000; }
  .in-the-know-left-column .event-section span {
    display: block;
    font-size: 16px;
    margin-bottom: 10px; }
    .in-the-know-left-column .event-section span a {
      color: #ffffff;
      font-size: 16px;
      background-image: linear-gradient(transparent calc(100% - 2px), #fff 2px); }
      @media (min-width: 768px) {
        .in-the-know-left-column .event-section span a {
          font-size: 24px; } }
  @media (min-width: 768px) {
    .in-the-know-right-column {
      width: 60%;
      padding: 0 20px; } }
  .in-the-know-right-column-featured-tile {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: #ffffff;
    margin-bottom: 20px;
    align-items: center; }
    .in-the-know-right-column-featured-tile:last-child {
      margin-bottom: 0; }
    .in-the-know-right-column-featured-tile-left {
      width: 40%; }
      @media (max-width: 768px) {
        .in-the-know-right-column-featured-tile-left {
          width: 100%; } }
      .in-the-know-right-column-featured-tile-left img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 312px; }
    .in-the-know-right-column-featured-tile-right {
      flex: 1;
      padding: 20px; }
      @media (min-width: 768px) {
        .in-the-know-right-column-featured-tile-right {
          padding: 40px; } }
      .in-the-know-right-column-featured-tile-right .arrow-animation {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: #3B58A2;
        display: inline-flex;
        cursor: pointer; }
        @media (max-width: 768px) {
          .in-the-know-right-column-featured-tile-right .arrow-animation {
            font-size: 20px; } }
        .in-the-know-right-column-featured-tile-right .arrow-animation:hover {
          color: #3B58A2; }
      .in-the-know-right-column-featured-tile-right h2 {
        color: #10242F;
        font-size: 20px;
        margin-top: 20px; }
        @media (min-width: 768px) {
          .in-the-know-right-column-featured-tile-right h2 {
            font-size: 24px; } }
        .in-the-know-right-column-featured-tile-right h2 a {
          color: #10242F; }
          .in-the-know-right-column-featured-tile-right h2 a:before {
            background-color: #10242F; }

.in-the-know.light {
  background-color: #E5E1E6; }
  .in-the-know.light .in-the-know-title, .in-the-know.light .in-the-know-sub-title {
    color: #000; }

.in-the-know.dark {
  background-color: #000; }
  .in-the-know.dark .in-the-know-title, .in-the-know.dark .in-the-know-sub-title {
    color: #ffffff; }

.arrow-animation {
  margin-right: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none; }
  .arrow-animation:hover .animated-arrow .first-arrow {
    left: 30px;
    opacity: 0; }
  .arrow-animation:hover .animated-arrow .second-arrow {
    left: 0;
    opacity: 1; }
  .arrow-animation:last-child {
    margin-right: 0; }
  .arrow-animation .animated-arrow {
    margin-left: 10px;
    position: relative;
    width: 20px;
    overflow: hidden;
    height: 20px;
    display: inline-flex; }
    .arrow-animation .animated-arrow > span {
      position: absolute;
      transition: left 0.5s ease-in, opacity 0.5s ease-in;
      top: 0; }
    .arrow-animation .animated-arrow .first-arrow {
      left: 0;
      opacity: 1; }
    .arrow-animation .animated-arrow .second-arrow {
      left: -36px;
      opacity: 0; }

a.underline-animation {
  text-decoration: none;
  position: relative; }
  a.underline-animation:hover:before {
    visibility: visible;
    width: 100%; }
  a.underline-animation:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FFF;
    visibility: hidden;
    transition: all 0.3s ease-in-out; }
  @media (min-width: 768px) {
    a.underline-animation {
      font-size: 24px; } }

a.multiline-animation {
  width: calc(100%);
  background-image: linear-gradient(transparent calc(100% - 2px), #000 2px);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 1s;
  text-decoration: none; }
  a.multiline-animation:hover {
    background-size: 100% 100%; }

.footer {
  background: #000;
  position: relative;
  z-index: 1; }
  .footer a:hover {
    color: #0047BB;
    text-decoration: none; }
  .footer.light {
    background: #E5E1E6; }
    .footer.light .footer-top-left-btnlist a {
      border: 1px solid #000; }
    .footer.light .footer-top-left-social li {
      border: 1px solid #000; }
      .footer.light .footer-top-left-social li img {
        filter: invert(1); }
      .footer.light .footer-top-left-social li:hover {
        border: 2px solid #000; }
    .footer.light a, .footer.light ul, .footer.light li, .footer.light div, .footer.light p, .footer.light i, .footer.light h3 {
      color: #000; }
    @media (max-width: 768px) {
      .footer.light .footer-top-right-column {
        border-bottom: 1px solid #000; } }
    @media (min-width: 768px) {
      .footer.light .footer-top-right-column.column1 a:before {
        background-color: #000; } }
    .footer.light svg path {
      fill: #000; }
    .footer.light-top-left-btnlist a {
      border: 1px solid rgba(0, 0, 0, 0.3); }
    .footer.light-top-left-social li {
      border: 1px solid rgba(0, 0, 0, 0.3); }
  @media (max-width: 768px) {
    .footer-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center; } }
  .footer a, .footer ul, .footer li, .footer div, .footer p, .footer i, .footer h3 {
    color: #ffffff; }
  .footer ul {
    padding: 0;
    list-style-type: none; }
  .footer i {
    font-family: 'FontAwesome';
    font-size: 20px; }
  .footer-top {
    padding: 54px 0 70px; }
    @media (max-width: 768px) {
      .footer-top {
        width: 100%;
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        justify-content: center; } }
    @media (max-width: 768px) {
      .footer-top-left > div {
        margin-bottom: 40px;
        margin-left: 20px;
        margin-right: 20px; } }
    @media (min-width: 768px) {
      .footer-top-left {
        width: 23%; } }
    @media (min-width: 768px) {
      .footer-top-left-logo {
        margin-bottom: 48px; } }
    .footer-top-left-btnlist {
      display: flex; }
      @media (min-width: 768px) {
        .footer-top-left-btnlist {
          margin-bottom: 48px; } }
      .footer-top-left-btnlist a {
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-size: 16px;
        line-height: 110%;
        padding: 12px;
        margin-right: 16px;
        width: 100%;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-transform: capitalize; }
        .footer-top-left-btnlist a:hover {
          text-decoration: none;
          color: #0047BB !important; }
          .footer-top-left-btnlist a:hover .animated-arrow .first-arrow {
            left: 30px;
            opacity: 0; }
          .footer-top-left-btnlist a:hover .animated-arrow .second-arrow {
            left: 0;
            opacity: 1; }
        .footer-top-left-btnlist a:last-child {
          margin-right: 0; }
        .footer-top-left-btnlist a .animated-arrow {
          margin-left: 10px;
          position: relative;
          width: 20px;
          overflow: hidden;
          height: 20px; }
          .footer-top-left-btnlist a .animated-arrow > span {
            position: absolute;
            transition: left 0.5s ease-in, opacity 0.5s ease-in;
            top: 0; }
          .footer-top-left-btnlist a .animated-arrow .first-arrow {
            left: 0;
            opacity: 1; }
          .footer-top-left-btnlist a .animated-arrow .second-arrow {
            left: -36px;
            opacity: 0; }
    .footer-top-left-social {
      display: flex;
      padding: 0;
      flex-wrap: wrap;
      margin: 0;
      max-width: 100%; }
      @media (max-width: 768px) {
        .footer-top-left-social {
          justify-content: center;
          display: none; } }
      .footer-top-left-social li {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        height: 56px;
        width: 56px;
        min-width: 56px;
        margin: 0 12px 12px 0; }
        .footer-top-left-social li:hover {
          cursor: pointer;
          color: #0047BB; }
        .footer-top-left-social li:last-child {
          margin-right: 0; }
    @media (max-width: 768px) {
      .footer-top-right {
        text-align: center; } }
    @media (min-width: 768px) {
      .footer-top-right {
        width: 74%;
        padding-left: 10%;
        font-weight: 600; }
        .footer-top-right-container {
          display: flex;
          flex-wrap: wrap;
          margin-left: -20px;
          margin-right: -20px; }
        .footer-top-right-column {
          width: 25%;
          padding-left: 20px;
          padding-right: 20px; }
          .footer-top-right-column.column1 ul li:first-child a {
            font-size: 18px; }
          .footer-top-right-column.column1 a {
            font-size: 18px;
            position: relative;
            text-decoration: none;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: capitalize; }
            .footer-top-right-column.column1 a:hover {
              text-decoration: none;
              color: #0047BB; }
              .footer-top-right-column.column1 a:hover:before {
                visibility: visible;
                width: 100%; }
          .footer-top-right-column ul li a {
            font-size: 16px; }
            .footer-top-right-column ul li a:hover {
              color: #0047BB;
              text-decoration: none; }
          .footer-top-right-column ul li:first-child a {
            font-size: 18px; }
        .footer-top-right-title {
          display: none; } }
    @media (min-width: 768px) and (max-width: 768px) {
      .footer-top-right-title {
        display: block; } }
    @media (min-width: 768px) {
        .footer-top-right-list {
          margin: 0; }
          .footer-top-right-list-item {
            margin-bottom: 20px; } }
  .footer-bottom {
    padding: 0 0 40px; }
    .footer-bottom .footer-top-left-social {
      display: none; }
      @media (max-width: 768px) {
        .footer-bottom .footer-top-left-social {
          display: flex;
          flex-wrap: wrap;
          max-width: 100%; } }
    .footer-bottom-right-list-item {
      font-size: 12px;
      margin: 15px 0 0 0; }
    .footer-bottom-left span {
      font-size: 14px; }
    @media (min-width: 768px) {
      .footer-bottom-left {
        width: 20%; }
      .footer-bottom-right {
        width: 80%;
        display: flex;
        justify-content: flex-end; }
        .footer-bottom-right-list {
          display: flex;
          justify-content: center;
          margin-top: 0; }
          .footer-bottom-right-list-item {
            font-size: 14px;
            margin: 0 20px 0 0;
            text-decoration: none; } }
  .footer-top, .footer-bottom {
    font-family: Poppins;
    font-weight: 400; }
    @media (min-width: 768px) {
      .footer-top, .footer-bottom {
        display: flex;
        flex-wrap: wrap;
        width: 100%; } }

@media (max-width: 768px) {
  .footer-top-right-column {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 25px 0; }
    .footer-top-right-column ul li {
      margin-top: 20px; }
    .footer-top-right-column.column1 h3 {
      display: none; }
    .footer-top-right-column.column1 ul {
      font-size: 20px; }
  .footer-top-right-title {
    margin: 0;
    position: relative;
    font-weight: 300;
    line-height: 1; }
    .footer-top-right-title::after {
      position: absolute;
      content: "\f105";
      font-family: FontAwesome;
      margin-left: 15px;
      font-size: 20px;
      transform: rotate(90deg); }
  .footer-top-right-list {
    display: none;
    margin: 0; }
  .footer-top-right-column .footer-top-right-list-active {
    display: block; }
  .footer-top-right-column.column1 {
    display: block; }
    .footer-top-right-column.column1 .footer-top-right-title::after {
      content: none; }
    .footer-top-right-column.column1 .footer-top-right-list {
      display: block; }
  .footer-top-right-column-active h3::after {
    transform: rotate(270deg) !important; } }

@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateX(0px) scale(1); }
  100% {
    opacity: 1;
    transform: translateX(0px) scale(1); } }

.header {
  position: relative;
  width: 100%;
  top: 0;
  z-index: 100;
  font-family: Poppins;
  font-weight: 600; }
  .header .container-fluid {
    padding: 0; }

.header-container-main {
  display: flex;
  padding: 20px 20px;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 768px) {
    .header-container-main {
      width: 100%;
      padding: 8px 12px 8px 12px;
      position: relative;
      background-color: #000;
      flex-direction: column; } }
  @media (max-width: 768px) {
    .header-container-main .logo-container {
      width: 100%;
      text-align: center;
      margin-bottom: 5px; } }
  .header-container-main .logo-container img {
    height: auto; }

.header-content {
  align-items: center; }
  @media (max-width: 768px) {
    .header-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-top: 1px solid #2b2b2b;
      margin-top: 9px;
      padding-top: 10px; } }
  .header-content #headerSearchInputMobile {
    border-bottom: 1px solid #000 !important;
    background: transparent;
    color: #000;
    width: 63%; }
  .header-content .close-mobile-search {
    padding-bottom: 46px; }
    .header-content .close-mobile-search a {
      float: right;
      color: #000; }
  .header-content .keyword-search input {
    border: none;
    border-bottom: 1px solid #fff !important;
    background: transparent;
    color: #fff; }
  .header-content .keyword-search button {
    background-color: transparent;
    position: relative;
    right: 0;
    top: 0;
    padding: 0; }
    @media (min-width: 768px) {
      .header-content .keyword-search button {
        position: absolute;
        right: -20px;
        bottom: 2px; } }
  .header-content .header-search {
    display: inline-flex; }
  .header-content #headerSearchInput {
    display: none; }
    @media (min-width: 768px) {
      .header-content #headerSearchInput {
        display: block; } }

.logo-img-white, .logo-img-transparent {
  display: none; }

.search-img, .search-img-mobile, .menu-img {
  font-family: 'FontAwesome';
  font-style: normal;
  font-size: 32px;
  font-weight: normal;
  cursor: pointer; }

.search-img {
  display: none; }
  @media (min-width: 768px) {
    .search-img {
      display: block;
      margin-right: 36px; } }

.search-img-mobile {
  display: block;
  margin-right: 0;
  font-size: 32px; }
  @media (min-width: 768px) {
    .search-img-mobile {
      display: none; } }

.search-img-inside {
  font-size: 24px;
  color: #000; }

.people-header {
  margin-right: 20px; }
  .people-header img {
    padding-right: 6px;
    padding-bottom: 5px; }
  .people-header span {
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; }
    .people-header span:hover {
      color: #0047BB; }
    .people-header span:hover {
      text-decoration: none; }

.close-btn {
  display: none;
  position: absolute;
  right: 43px;
  z-index: 1;
  color: black;
  top: 10px; }
  .close-btn button {
    background: transparent;
    border: none;
    color: #000;
    font-size: 15px; }

.header.white-bg {
  background-color: #fff; }
  .header.white-bg .logo-img-white {
    display: block; }
  .header.white-bg .search-img, .header.white-bg .search-img-mobile, .header.white-bg .menu-img {
    color: #000;
    font-size: 20px; }
    @media (max-width: 768px) {
      .header.white-bg .search-img, .header.white-bg .search-img-mobile, .header.white-bg .menu-img {
        color: black; } }

.header.transparent-bg {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.28); }
  @media (max-width: 768px) {
    .header.transparent-bg .header-container-main {
      background: rgba(0, 0, 0, 0.28); } }
  .header.transparent-bg .logo-img-transparent {
    display: block; }
    .header.transparent-bg .logo-img-transparent img {
      height: auto; }
  .header.transparent-bg .search-img, .header.transparent-bg .search-img-mobile, .header.transparent-bg .menu-img {
    color: #fff;
    font-size: 20px; }

.header.black-bg {
  background: #000; }
  .header.black-bg .logo-img-transparent {
    display: block; }
    .header.black-bg .logo-img-transparent img {
      height: auto; }
  .header.black-bg .search-img, .header.black-bg .search-img-mobile, .header.black-bg .menu-img {
    color: #fff;
    font-size: 20px; }

.search-bar {
  display: none; }

.search-bar-container {
  padding: 40px;
  background: white;
  margin: 0;
  position: absolute;
  width: 100%;
  top: -1px;
  left: 0;
  right: 0; }
  .search-bar-container .logo-container {
    display: none; }
    @media (min-width: 768px) {
      .search-bar-container .logo-container {
        display: block;
        position: absolute;
        top: 5px;
        left: 40px; }
        .search-bar-container .logo-container .logo-img-white {
          display: block; } }
  .search-bar-container .header-search {
    border-bottom: 1px solid #000;
    max-width: 640px;
    display: flex;
    margin: 40px auto 0;
    justify-content: space-between;
    align-items: stretch;
    text-align: center;
    background-color: #fff; }
    @media (max-width: 768px) {
      .search-bar-container .header-search {
        width: 100%;
        margin: 0; } }
    .search-bar-container .header-search input {
      border: none;
      padding: 10px 40px 10px 10px;
      width: 100%;
      max-width: 700px;
      color: #000; }
    .search-bar-container .header-search div {
      display: flex;
      align-items: center;
      background: #fff;
      padding-right: 5px; }
    .search-bar-container .header-search button {
      position: relative;
      margin-left: 20px;
      background-color: transparent; }
      .search-bar-container .header-search button.search_reset i:before {
        content: "\f00d"; }

.side-nav {
  width: 393px;
  background-color: #fff;
  height: 100vh;
  position: absolute;
  right: -100%;
  padding: 30px;
  overflow-y: scroll;
  transition: right 0.5s;
  top: 0; }
  @media (max-width: 768px) {
    .side-nav {
      width: 100%; } }

.side-nav-container {
  display: none; }
  @media (min-width: 768px) {
    .side-nav-container {
      display: block; } }
  .side-nav-container:before {
    content: "";
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    height: 100%;
    width: 100%; }
  .side-nav-container.side-nav-active:before {
    display: block; }
  .side-nav-container.side-nav-active .side-nav {
    right: 0; }

.menu-close-btn {
  text-align: right; }
  .menu-close-btn button {
    background: transparent;
    border: none;
    color: #000; }

.primary-header-list ul {
  margin: 0;
  padding: 40px 0;
  border-bottom: 1px solid #D8D8D8; }
  .primary-header-list ul .primary-header-list-item {
    list-style: none; }
    .primary-header-list ul .primary-header-list-item .primary-header-list-link {
      text-decoration: none;
      color: #000;
      font-size: 28px; }
      .primary-header-list ul .primary-header-list-item .primary-header-list-link:hover {
        color: #0047BB; }

.secondary-header-list ul {
  margin: 0;
  padding: 40px 0;
  line-height: 2; }
  .secondary-header-list ul .secondary-header-list-item {
    list-style: none; }
    .secondary-header-list ul .secondary-header-list-item .secondary-header-list-link {
      text-decoration: none;
      color: #000;
      font-size: 18px;
      font-weight: 600; }
      .secondary-header-list ul .secondary-header-list-item .secondary-header-list-link:hover {
        color: #0047BB; }
    .secondary-header-list ul .secondary-header-list-item .secondary-header-list-subitem {
      display: block;
      margin-left: 15px;
      padding-left: 5px;
      font-size: small;
      border-left: gray;
      border-left-width: 1px;
      border-left-style: dotted;
      margin-bottom: 2px; }
      .secondary-header-list ul .secondary-header-list-item .secondary-header-list-subitem .secondary-header-list-submenu-link {
        text-decoration: none;
        color: #171717;
        font-size: medium;
        color: dimgray; }
        .secondary-header-list ul .secondary-header-list-item .secondary-header-list-subitem .secondary-header-list-submenu-link:hover {
          color: #0047BB; }

.home > div, .home > div.container-fluid {
  max-width: 100%;
  padding: 0; }

.container-fluid {
  max-width: 1440px; }

.bbt-pageheader > .container-fluid {
  flex-direction: row;
  display: flex; }

.primary-carousel {
  display: none !important;
  background: linear-gradient(180deg, #E5E1E6 50%, #C0C3D8 100%); }
  @media (min-width: 768px) {
    .primary-carousel {
      display: block !important; } }
  .primary-carousel-cardImg {
    height: 120px;
    width: 100%; }
    .primary-carousel-cardImg img {
      height: inherit;
      width: 100%;
      object-fit: cover; }
  .primary-carousel-bgImg {
    height: 368px; }
    .primary-carousel-bgImg img {
      height: 100%;
      aspect-ratio: 16/9;
      object-fit: cover; }
  .primary-carousel-slide_content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
    gap: 26px;
    margin-inline-start: 16px;
    margin-inline-end: 16px;
    margin-top: 16px;
    margin-bottom: 16px; }
    @media (min-width: 768px) {
      .primary-carousel-slide_content {
        margin-inline-start: 64px;
        margin-inline-end: 64px;
        margin-top: 48px; } }
  .primary-carousel-card {
    display: flex;
    flex-basis: 100%;
    height: 368px;
    background: #ffffff;
    box-shadow: 5px 10px 10px 0px #898B9D;
    position: relative; }
    .primary-carousel-card .card-text-container {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 20px;
      padding-top: 20px; }
      @media (min-width: 768px) {
        .primary-carousel-card .card-text-container {
          padding: initial; } }
      .primary-carousel-card .card-text-container p {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 19.6px */
        text-transform: uppercase;
        margin: 20px 0 0; }
      .primary-carousel-card .card-text-container .card-title {
        margin-top: 10px; }
        .primary-carousel-card .card-text-container .card-title a {
          color: #0047BB;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; }
          @media (min-width: 768px) and (max-width: 1024px) {
            .primary-carousel-card .card-text-container .card-title a {
              font-size: 12px; } }
          .primary-carousel-card .card-text-container .card-title a img {
            margin-top: 10px; }
    .primary-carousel-card .full-bg {
      position: absolute;
      padding: 20px;
      bottom: 0;
      transition: height 0.2s ease-in-out; }
      .primary-carousel-card .full-bg p {
        color: #ffffff; }
      .primary-carousel-card .full-bg .card-title a {
        color: #ffffff; }
  .primary-carousel-lite {
    display: block !important; }
    @media (min-width: 768px) {
      .primary-carousel-lite {
        display: none !important; } }

.primary-carousel-card:has(.primary-carousel-cardImg), .primary-carousel-card:not(:has(.primary-carousel-bgImg)) {
  flex-direction: column; }
  @media (min-width: 768px) {
    .primary-carousel-card:has(.primary-carousel-cardImg), .primary-carousel-card:not(:has(.primary-carousel-bgImg)) {
      padding: 20px; } }

.slick-dots li {
  margin: 0 24px !important;
  padding: 5px !important; }
  @media (min-width: 768px) {
    .slick-dots li {
      margin: 0 32px !important; } }

.slick-dots li button:before {
  width: 18px !important;
  height: 18px !important;
  content: '' !important;
  border-radius: 17.5px !important;
  display: inline-block !important;
  border: 2px solid #0047BB !important; }

.slick-dots li.slick-active button:before {
  opacity: 1;
  background-color: #0047BB;
  content: ''; }

.slick-dots {
  position: initial !important;
  width: initial !important;
  padding: 5px !important; }

.slides-arrows-full, .slides-arrows-lite {
  display: flex;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 48px; }

.slick-slider {
  margin-bottom: initial !important; }

.experience-toggle-panel h2 {
  color: #ffffff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 46.8px */ }
  @media (min-width: 768px) {
    .experience-toggle-panel h2 {
      font-size: 36px; } }

.experience-toggle-panel_services, .experience-toggle-panel_regions {
  padding: 16px; }
  @media (min-width: 768px) {
    .experience-toggle-panel_services, .experience-toggle-panel_regions {
      padding: 40px; } }
  .experience-toggle-panel_services .toggle-container, .experience-toggle-panel_regions .toggle-container {
    position: relative; }
    .experience-toggle-panel_services .toggle-container .ser-toggle-btn, .experience-toggle-panel_services .toggle-container .geo-toggle-btn, .experience-toggle-panel_services .toggle-container .toggle-btn, .experience-toggle-panel_regions .toggle-container .ser-toggle-btn, .experience-toggle-panel_regions .toggle-container .geo-toggle-btn, .experience-toggle-panel_regions .toggle-container .toggle-btn {
      border-radius: 11px;
      border: 1px solid #C0C3D8;
      background: #C0C3D8;
      color: #0047BB;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
      text-transform: uppercase;
      border-radius: 11px;
      padding-left: 8px;
      padding-right: 8px;
      margin-top: 10px; }
      .experience-toggle-panel_services .toggle-container .ser-toggle-btn.active, .experience-toggle-panel_services .toggle-container .geo-toggle-btn.active, .experience-toggle-panel_services .toggle-container .toggle-btn.active, .experience-toggle-panel_regions .toggle-container .ser-toggle-btn.active, .experience-toggle-panel_regions .toggle-container .geo-toggle-btn.active, .experience-toggle-panel_regions .toggle-container .toggle-btn.active {
        border: 1px solid #6cace4;
        background: #ffffff;
        color: #000;
        padding-right: 16px;
        position: relative;
        z-index: 11; }
    .experience-toggle-panel_services .toggle-container .ser-toggle-btn, .experience-toggle-panel_regions .toggle-container .ser-toggle-btn {
      padding-left: 20px;
      position: absolute;
      left: 73px;
      z-index: 10px; }
    .experience-toggle-panel_services .toggle-container .geo-toggle-btn, .experience-toggle-panel_regions .toggle-container .geo-toggle-btn {
      padding-right: 20px; }
    .experience-toggle-panel_services .toggle-container .geo-toggle-btn + .toggle-btn, .experience-toggle-panel_regions .toggle-container .geo-toggle-btn + .toggle-btn {
      position: absolute;
      left: 73px;
      padding-right: 8px; }

.experience-toggle-panel .panel {
  display: none; }

.experience-toggle-panel .panel.active {
  display: block; }

.experience-toggle-panel_regions .content {
  padding-top: 52px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  width: 100%; }
  @media (min-width: 768px) {
    .experience-toggle-panel_regions .content {
      grid-template-columns: 1fr 1fr 1fr;
      width: 55%;
      column-gap: 40px; } }
  .experience-toggle-panel_regions .content p {
    margin: 0;
    display: inline-block;
    width: 100%; }
  .experience-toggle-panel_regions .content a {
    display: inline-block; }
    .experience-toggle-panel_regions .content a span {
      color: #ffffff;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      /* 39.2px */
      text-transform: capitalize; }
      @media (min-width: 768px) {
        .experience-toggle-panel_regions .content a span {
          font-size: 28px; } }

.experience-toggle-panel_regions-title {
  color: #ffffff;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 70.2px */
  width: 100%;
  padding-top: 86px;
  padding-bottom: 64px; }
  @media (min-width: 768px) {
    .experience-toggle-panel_regions-title {
      width: 60%;
      font-size: 54px; } }

.experience-toggle-panel_services .content {
  padding-top: 56px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 16px;
  width: 100%; }
  @media (min-width: 768px) {
    .experience-toggle-panel_services .content {
      grid-template-columns: 1fr 1fr 1fr 1fr; } }
  .experience-toggle-panel_services .content p {
    margin: 0;
    display: inline-block;
    width: 100%; }
  .experience-toggle-panel_services .content a {
    display: inline-block; }
    .experience-toggle-panel_services .content a span {
      color: #ffffff;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      /* 28px */
      text-transform: capitalize; }
      @media (min-width: 768px) {
        .experience-toggle-panel_services .content a span {
          font-size: 20px; } }
      .experience-toggle-panel_services .content a span:hover {
        color: #8b8b8b; }

.experience-toggle-panel input[type="radio"].toggle {
  display: none; }
  .experience-toggle-panel input[type="radio"].toggle + label {
    position: relative;
    cursor: pointer;
    min-width: 60px; }
    .experience-toggle-panel input[type="radio"].toggle + label:hover {
      background: none;
      color: yellow; }
    .experience-toggle-panel input[type="radio"].toggle + label:after {
      background: red;
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
      width: 100%;
      z-index: 0;
      opacity: 0.5; }
  .experience-toggle-panel input[type="radio"].toggle.toggle-left + label {
    border-right: 0; }
    .experience-toggle-panel input[type="radio"].toggle.toggle-left + label:after {
      left: 0; }
  .experience-toggle-panel input[type="radio"].toggle.toggle-right + label {
    margin-left: -5px; }
    .experience-toggle-panel input[type="radio"].toggle.toggle-right + label:after {
      left: -100%; }
  .experience-toggle-panel input[type="radio"].toggle:checked + label {
    cursor: default;
    color: #fff;
    transition: color 200ms; }
    .experience-toggle-panel input[type="radio"].toggle:checked + label:after {
      left: 0; }

.featured-news-container {
  background-color: #000;
  font-family: Poppins;
  color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .featured-news-container {
      padding: 50px 30px; } }
  .featured-news-container .featured-news-title h2 {
    font-size: 36px; }
    @media (min-width: 768px) {
      .featured-news-container .featured-news-title h2 {
        font-size: 56px; } }
  .featured-news-container .featured-news-title .featured-news-sub-title {
    display: flex;
    align-items: center; }
  .featured-news-container .featured-news-title h3 {
    font-size: 20px;
    font-style: normal;
    line-height: 130%; }
    @media (min-width: 768px) {
      .featured-news-container .featured-news-title h3 {
        font-size: 28px; } }
  .featured-news-container a {
    text-decoration: underline;
    color: #000; }
  .featured-news-container .news-card-container {
    display: block;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: left;
    align-items: flex-start;
    gap: 30px;
    overflow: hidden;
    margin-top: 40px; }
    .featured-news-container .news-card-container .slick-track {
      margin: 0 auto;
      display: flex; }
    @media (min-width: 769px) {
      .featured-news-container .news-card-container {
        display: flex; } }
  .featured-news-container .slick-dots li.slick-active button:before {
    color: #ffffff; }
  .featured-news-container .slick-dots li button:before {
    color: #ffffff; }
  .featured-news-container .news-card-content {
    padding-right: 8px; }
    @media (min-width: 768px) {
      .featured-news-container .news-card-content {
        padding-right: 0; } }
  .featured-news-container .image-animate {
    overflow: hidden;
    border: 5px solid transparent;
    transition: all 0.5s ease-in-out; }
    .featured-news-container .image-animate:hover {
      border: 5px solid #0047BB; }
      .featured-news-container .image-animate:hover img {
        transform: scale(1.2); }
  .featured-news-container .slick-list {
    padding-left: 0 !important; }
  @media (min-width: 768px) {
    .featured-news-container .news-card {
      max-width: 600px;
      width: 33.33%; } }
  .featured-news-container .news-card img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease-in-out; }
  .featured-news-container .news-card .featured-news-headline {
    color: #ffffff; }
    .featured-news-container .news-card .featured-news-headline:hover {
      color: #ffffff; }
  .featured-news-container .news-cardHeadline {
    text-decoration: underline; }
  .featured-news-container .featured-news-headline {
    color: #ffffff; }
    .featured-news-container .featured-news-headline:hover {
      color: #6cace4; }
  .featured-news-container .slick-dots {
    display: flex;
    justify-content: center;
    margin: 20px 0 0 0;
    padding: 1rem 0;
    list-style-type: none; }
    .featured-news-container .slick-dots li {
      margin: 0 0.25rem; }
      .featured-news-container .slick-dots li.slick-active button {
        background: #6cace4;
        width: 10px;
        height: 10px; }
      .featured-news-container .slick-dots li button {
        display: block;
        width: 8px;
        height: 8px;
        padding: 0;
        border: none;
        border-radius: 100%;
        background-color: #C4C4C4;
        text-indent: -9999px; }

.featured-news-container.light {
  background-color: #e5e1e6;
  color: #000; }
  .featured-news-container.light .featured-news-title h3 {
    color: #0047BB; }
  .featured-news-container.light .featured-news-cta {
    color: #0047BB; }
    .featured-news-container.light .featured-news-cta span:after {
      color: #0047BB; }
  .featured-news-container.light .news-card-container h2 {
    color: #000; }
  .featured-news-container.light .featured-news-headline:hover {
    color: #000; }
  .featured-news-container.light .featured-news-cta:hover {
    color: #ce1126; }
  .featured-news-container.light .slick-dots li.slick-active button:before {
    color: #ffffff; }
  .featured-news-container.light .slick-dots li button:before {
    color: #ffffff; }
  .featured-news-container.light .slick-dots li.slick-active button {
    background: #000; }

@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateX(0px) scale(1); }
  25% {
    opacity: 0;
    transform: translateX(10px) scale(0.9); }
  26% {
    opacity: 0;
    transform: translateX(-10px) scale(0.9); }
  55% {
    opacity: 1;
    transform: translateX(0px) scale(1); } }

a.rounded-button-container {
  position: relative;
  color: #6cace4;
  text-decoration: none;
  margin-top: 32px;
  display: inline-flex;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-transform: capitalize;
  align-items: center;
  text-decoration: none; }
  @media (max-width: 768px) {
    a.rounded-button-container {
      margin-top: 26px;
      font-size: 20px; } }
  a.rounded-button-container:hover {
    color: #ce1127; }
    a.rounded-button-container:hover .first-arrow {
      left: 71px;
      opacity: 0; }
    a.rounded-button-container:hover .second-arrow {
      left: 50%;
      opacity: 1; }
    a.rounded-button-container:hover .second-arrow svg path, a.rounded-button-container:hover .first-arrow svg path {
      stroke: #ffffff; }
    a.rounded-button-container:hover .animated-arrow-rounded {
      border: 1px solid #ce1127; }
      a.rounded-button-container:hover .animated-arrow-rounded::after {
        animation-name: bounceAlpha;
        animation-duration: 1.4s;
        animation-delay: 0.4s;
        animation-iteration-count: infinite;
        animation-timing-function: linear; }
      a.rounded-button-container:hover .animated-arrow-rounded::before {
        transform: translateX(0); }
  a.rounded-button-container .first-arrow, a.rounded-button-container .second-arrow {
    font-family: Fontawesome;
    color: #6cace4;
    border: none;
    position: absolute;
    transition: left 0.2s ease-in, opacity 0.2s ease-in;
    font-size: 26px;
    top: 50%;
    line-height: 0;
    transform: translate(-50%, -50%); }
    a.rounded-button-container .first-arrow svg, a.rounded-button-container .second-arrow svg {
      height: 25px;
      width: 25px; }
  a.rounded-button-container .first-arrow {
    left: 50%;
    opacity: 1; }
  a.rounded-button-container .second-arrow {
    left: -36px;
    opacity: 0; }
  a.rounded-button-container .animated-arrow-rounded {
    height: 48px;
    width: 48px;
    border: 1px solid #6cace4;
    border-radius: 50%;
    line-height: 1;
    overflow: hidden;
    position: relative;
    margin-left: 25px; }
    @media (min-width: 768px) {
      a.rounded-button-container .animated-arrow-rounded {
        height: 72px;
        width: 72px; } }
    a.rounded-button-container .animated-arrow-rounded::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #ce1127;
      transform: translateX(-100%);
      transition: transform 0.4s ease-in-out;
      border-radius: 50%; }

.statInfo-container {
  background-color: #000;
  color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins'; }
  @media (min-width: 768px) {
    .statInfo-container {
      padding: 50px 30px; } }
  .statInfo-container.light {
    background: #E5E1E6; }
    .statInfo-container.light .statInfo-firstTitle, .statInfo-container.light .statInfo-statisticNumber .finalNumber {
      color: #0047BB; }
    .statInfo-container.light .statInfo-statistic .statInfo-statisticNumber {
      color: #0047BB; }
    .statInfo-container.light .statInfo-secondTitle, .statInfo-container.light .statInfo-statisticBody {
      color: #000;
      text-align: left; }
  .statInfo-container .statInfo-titleWrapper {
    box-sizing: content-box;
    height: 125px;
    overflow: hidden; }
    .statInfo-container .statInfo-titleWrapper span {
      display: block;
      height: 100%;
      animation: spin_words 4s infinite; }
  .statInfo-container .statInfo-firstTitle, .statInfo-container .statInfo-secondTitle {
    font-size: 40px;
    line-height: 1.25; }
    @media (min-width: 768px) {
      .statInfo-container .statInfo-firstTitle, .statInfo-container .statInfo-secondTitle {
        font-size: 68px; } }
  .statInfo-container .statInfo-firstTitle {
    color: #6cace4;
    display: block; }
  .statInfo-container .statInfo-statisticList {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: row; }
    @media (max-width: 768px) {
      .statInfo-container .statInfo-statisticList {
        flex-direction: column; } }
  .statInfo-container .statInfo-statistic {
    padding: 20px;
    border: 1px solid rgba(108, 172, 228, 0.3);
    border-bottom: 0; }
    @media (min-width: 768px) {
      .statInfo-container .statInfo-statistic {
        padding: 65px 40px;
        border-right: 0;
        border-bottom: 1px solid rgba(108, 172, 228, 0.3);
        text-align: center; } }
    .statInfo-container .statInfo-statistic:last-child {
      border-bottom: 1px solid rgba(108, 172, 228, 0.3); }
      @media (min-width: 768px) {
        .statInfo-container .statInfo-statistic:last-child {
          border-right: 1px solid rgba(108, 172, 228, 0.3); } }
    .statInfo-container .statInfo-statistic .statInfo-statisticNumber {
      color: #6cace4;
      font-family: Poppins;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      margin: 0 0 20px;
      display: inline-block; }
  .statInfo-container .statInfo-statNumber {
    color: #6cace4;
    text-align: center;
    font-size: xxx-large;
    margin-bottom: 15px; }
  .statInfo-container .statInfo-statBody {
    text-align: left;
    padding-left: 40px;
    padding-right: 40px; }

@keyframes spin_words {
  10% {
    transform: translateY(-112%); }
  25% {
    transform: translateY(-100%); }
  35% {
    transform: translateY(-212%); }
  50% {
    transform: translateY(-200%); } }

.statInfo-wrapper {
  overflow: hidden;
  height: 50px; }
  @media (min-width: 768px) {
    .statInfo-wrapper {
      height: 170px; } }
  .statInfo-wrapper .statInfo-titleContent {
    margin-top: 0;
    text-align: left;
    list-style: none;
    -webkit-animation-name: change;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 10s;
    animation-iteration-count: infinite; }
    .statInfo-wrapper .statInfo-titleContent > span {
      line-height: 40px;
      margin: 0; }

@-webkit-keyframes change {
  0%, 12.66%, 100% {
    transform: translate3d(0, 0, 0); }
  16.66%, 29.32% {
    transform: translate3d(0, -25%, 0); }
  33.32%, 45.98% {
    transform: translate3d(0, -50%, 0); }
  49.98%, 62.64% {
    transform: translate3d(0, -75%, 0); }
  66.64%, 79.3% {
    transform: translate3d(0, -50%, 0); }
  83.3%, 95.96% {
    transform: translate3d(0, -25%, 0); } }

@-o-keyframes change {
  0%, 12.66%, 100% {
    transform: translate3d(0, 0, 0); }
  16.66%, 29.32% {
    transform: translate3d(0, -25%, 0); }
  33.32%, 45.98% {
    transform: translate3d(0, -50%, 0); }
  49.98%, 62.64% {
    transform: translate3d(0, -75%, 0); }
  66.64%, 79.3% {
    transform: translate3d(0, -50%, 0); }
  83.3%, 95.96% {
    transform: translate3d(0, -25%, 0); } }

@-moz-keyframes change {
  0%, 12.66%, 100% {
    transform: translate3d(0, 0, 0); }
  16.66%, 29.32% {
    transform: translate3d(0, -25%, 0); }
  33.32%, 45.98% {
    transform: translate3d(0, -50%, 0); }
  49.98%, 62.64% {
    transform: translate3d(0, -75%, 0); }
  66.64%, 79.3% {
    transform: translate3d(0, -50%, 0); }
  83.3%, 95.96% {
    transform: translate3d(0, -25%, 0); } }

@keyframes change {
  0%, 12.66%, 100% {
    transform: translate3d(0, 0, 0); }
  16.66%, 29.32% {
    transform: translate3d(0, -25%, 0); }
  33.32%, 45.98% {
    transform: translate3d(0, -50%, 0); }
  49.98%, 62.64% {
    transform: translate3d(0, -75%, 0); }
  66.64%, 79.3% {
    transform: translate3d(0, -50%, 0); }
  83.3%, 95.96% {
    transform: translate3d(0, -25%, 0); } }

.person-results-details {
  text-align: left;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 16px;
  border-top: 1px solid #ffffff;
  min-height: 50px;
  transition: min-height .2s ease-in; }
  .person-results-details a {
    text-decoration: none;
    color: #0047BB; }
    .person-results-details a:hover {
      color: red; }
  .person-results-details .contactInfo {
    color: #0047BB;
    font-size: 14px; }

body .global-page-title {
  background-image: linear-gradient(to right, #0047BB, #0047BB); }

body .bbt-listings .sector-tabs .sector-tab-view .service-content h2 a {
  color: #0047BB; }

body .grid-content-main .featured-news-container {
  margin-top: 40px; }

.secondary-carousel {
  background: linear-gradient(180deg, #E5E1E6 50%, #C0C3D8 100%);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px; }
  @media (min-width: 768px) {
    .secondary-carousel {
      padding-left: 64px;
      padding-right: 64px;
      padding-top: 64px; } }
  .secondary-carousel_slide {
    display: block;
    background: #ffffff; }
    @media (min-width: 768px) {
      .secondary-carousel_slide {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        gap: 20px;
        padding: 20px;
        box-shadow: -6px 1px 20px 3px rgba(137, 139, 157, 0.5); } }
    .secondary-carousel_slide-col {
      width: 100%; }
      @media (min-width: 768px) {
        .secondary-carousel_slide-col {
          width: 50%; } }
    .secondary-carousel_slide-col:first-child > img {
      height: 120px;
      width: 100%;
      object-fit: cover; }
      @media (min-width: 768px) {
        .secondary-carousel_slide-col:first-child > img {
          height: 320px; } }
    .secondary-carousel_slide-col:nth-child(2) {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 16px;
      padding-right: 16px; }
      @media (min-width: 768px) {
        .secondary-carousel_slide-col:nth-child(2) {
          padding-bottom: 20px;
          padding-left: initial;
          padding-right: initial; } }
      .secondary-carousel_slide-col:nth-child(2) p {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 19.6px */
        text-transform: uppercase; }
      .secondary-carousel_slide-col:nth-child(2) a > span {
        color: #0047BB;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 33.6px */ }
      .secondary-carousel_slide-col:nth-child(2) a > img {
        padding-top: 10px; }
        @media (min-width: 768px) {
          .secondary-carousel_slide-col:nth-child(2) a > img {
            padding-top: 30px; } }

.secondary-carousel-arrows {
  display: flex;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 48px; }
  @media (min-width: 768px) {
    .secondary-carousel-arrows {
      padding-top: 28px;
      padding-bottom: 28px; } }
