.header {
    position: relative;
    width: 100%;
    top: 0;
    z-index: 100;
    font-family: Poppins;
    font-weight: 600;

    .container-fluid {
        padding: 0;
    }
}

.header-container-main {
    display: flex;
    padding: 20px 20px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
        width: 100%;
        padding: 8px 12px 8px 12px;
        position: relative;
        background-color: $color-brand-black;
        flex-direction: column;
    }

    .logo-container {
        @media (max-width: 768px) {
            width: 100%;
            text-align: center;
            margin-bottom: 5px;
        }

        img {
            height: auto;
        }
    }
}

.header-content {
    align-items: center;

    @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-top: 1px solid #2b2b2b;
        margin-top: 9px;
        padding-top: 10px;
    }

    #headerSearchInputMobile{
        border-bottom: 1px solid $color-brand-black !important;
            background: transparent;
            color: $color-brand-black;
            width: 63%;

    }
    .close-mobile-search{
        padding-bottom: 46px;
            a{
                float: right;
                color: $color-brand-black;
            }
    }
    .keyword-search {
        input {
            border: none;
            border-bottom: 1px solid $color-brand-white !important;
            background: transparent;
            color: $color-brand-white;
        }
        button {
            background-color: transparent;
            
            position: relative;
            right: 0;
            top: 0;
            padding: 0;
            @media (min-width: 768px) {
                position: absolute; 
                right: -20px;   
                bottom: 2px;                 
            }

        }
    }
    .header-search{
        display: inline-flex;
    }

    #headerSearchInput {
        display: none;
        @media (min-width: 768px) {
display: block;
        }
    }
}

.logo-img-white, .logo-img-transparent {
    display: none;
}

.search-img,.search-img-mobile, .menu-img {
    font-family: 'FontAwesome';
    font-style: normal;
    font-size: 32px;
    font-weight: normal;
    cursor: pointer;
}

.search-img {
    display: none;
    @media (min-width: 768px) {
        display: block;
        margin-right: 36px;
                }
}

.search-img-mobile{
    display: block;
    margin-right: 0;
    font-size: 32px;
    @media (min-width: 768px) {
        display: none;
    }

}
.search-img-inside{
    font-size: 24px;
    color: $color-brand-black;
}
.people-header {
    margin-right: 20px;
    img {
        padding-right: 6px;
        padding-bottom: 5px;
    }
    span{
        color: $color-brand-white;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; 
        &:hover {
            color: $darkblue-update;     
        }
        &:hover{
            text-decoration: none; 
        }
    }
   
}

.close-btn {
    display: none;
    position: absolute;
    right: 43px;
    z-index: 1;
    color: black;
    top: 10px;

    button {
        background: transparent;
        border: none;
        color: $color-brand-black;
        font-size: 15px;
    }
}


.header.white-bg {
    background-color: $color-brand-white;

    .logo-img-white {
        display: block;
    }

    .search-img,.search-img-mobile, .menu-img {
        color: $color-brand-black;
        font-size: 20px;

        @media (max-width: 768px) {
            color: black
        }
    }
}

.header.transparent-bg {
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: rgba(0, 0, 0, 0.28);

    .header-container-main {

        @media (max-width: 768px) {
            background: rgba(0, 0, 0, 0.28);
        }
    }

    .logo-img-transparent {
        display: block;

        img {
            height: auto;
        }
    }

    .search-img,.search-img-mobile, .menu-img {
        color: $color-brand-white;
        font-size: 20px;
    }
}

.header.black-bg {
    background: $color-brand-black;
    .logo-img-transparent {
        display: block;

        img {
            height: auto;
        }
    }
    .search-img,.search-img-mobile, .menu-img {
        color: $color-brand-white;
        font-size: 20px;
    }
}

.search-bar {
    display: none;
}

.search-bar-container {
    padding: 40px;
    background: white;
    margin: 0;
    position: absolute;
    width: 100%;
    top: -1px;
    left: 0;
    right: 0;

    .logo-container {
        display: none;

        @media(min-width:768px) {
            display: block;
            position: absolute;
            top: 5px;
            left: 40px;

            .logo-img-white {
                display: block;
            }
        }
    }

    .header-search {
        border-bottom: 1px solid $color-brand-black;
        max-width: 640px;
        display: flex;
        margin: 40px auto 0;
        justify-content: space-between;
        align-items: stretch;
        text-align: center;
        background-color: $color-brand-white;

        @media (max-width: 768px) {
            width: 100%;
            margin: 0;
        }

        input {
            border: none;
            padding: 10px 40px 10px 10px;
            width: 100%;
            max-width: 700px;
            color: $color-brand-black;
        }

        div {
            display: flex;
            align-items: center;
            background: $color-brand-white;
            padding-right: 5px;
        }

        button {
            position: relative;
            margin-left: 20px;
            background-color: transparent;

            &.search_reset {
                i:before {
                    content: "\f00d";
                }
            }
        }
    }
}

.side-nav {
    width: 393px;
    background-color: $color-brand-white;
    height: 100vh;
    position: absolute;
    right: -100%;
    padding: 30px;
    overflow-y: scroll;
    transition: right 0.5s;
    top:0;

    @media (max-width: 768px) {
        width: 100%;
    }

}

.side-nav-container {
display: none;
    @media (min-width: 768px) {
        display: block;
    }

    &:before {
        content: "";
        background-color: rgba(0,0,0,0.7);
        display: none;
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
        height: 100%;
        width: 100%;
    }

    &.side-nav-active {
        &:before {
            display: block;
        }

        .side-nav {
            right: 0;
        }
    }
}



.menu-close-btn {
    text-align: right;

    button {
        background: transparent;
        border: none;
        color: $color-brand-black;
    }
}

.primary-header-list {
    ul {
        margin: 0;
        padding: 40px 0;
        border-bottom: 1px solid $grey-border-al2;

        .primary-header-list-item {
            list-style: none;

            .primary-header-list-link {
                text-decoration: none;
                color: $color-brand-black;
                font-size: 28px;

                &:hover {
                    color: $darkblue-update;
                }
            }
        }
    }
}

.secondary-header-list {
    ul {
        margin: 0;
        padding: 40px 0;
        line-height: 2;

        .secondary-header-list-item {
            list-style: none;

            .secondary-header-list-link {
                text-decoration: none;
                color: $color-brand-black;
                font-size: 18px;
                font-weight: 600;

                &:hover {
                    color: $darkblue-update;
                }
            }

            .secondary-header-list-subitem {
                display: block;
                margin-left: 15px;
                padding-left: 5px;
                font-size: small;
                border-left: gray;
                border-left-width: 1px;
                border-left-style: dotted;
                margin-bottom: 2px;

                .secondary-header-list-submenu-link {
                    text-decoration: none;
                    color: $color-brand-black-dull;
                    font-size: medium;
                    color: dimgray;

                    &:hover {
                        color: $darkblue-update;
                    }
                }
            }
        }
    }
}

.home {
    > div, > div.container-fluid {
        max-width: 100%;
        padding: 0;
    }
}


.container-fluid {
    max-width: 1440px;
 
}

.bbt-pageheader > .container-fluid{
    flex-direction: row;
    display: flex;
}
